import React, {Suspense, lazy} from "react";
import { Route, Routes,   useNavigate, useLocation, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const  Home = lazy(()=> import("./pages/home/home"));
const  Mobility = lazy(()=> import("./pages/projects/mobility/mobility"));
const  Esplte = lazy(()=> import("./pages/projects/esplte/esplte"));
const  ContactUs = lazy(()=> import("./pages/contact/contact"));
const  About = lazy(()=> import("./pages/about"));
const  Socialicons = lazy(()=> import("./components/socialicons/socialicons"));
const  Portfolio = lazy(()=> import("./pages/portfolio/portfolio"));
const  Games = lazy(()=> import("./pages/games/games"));
const  Spellingbee = lazy(()=> import("./pages/games/spellingbee/spellingbee"));
const  Wordle = lazy(()=> import("./pages/games/wordle/wordle"));
// const  CSSTransition, TransitionGroup = lazy(()=> import("react-transition-group"));
// import { Home } from "./pages/home/home";
// import { Mobility } from "./pages/mobility/mobility";
// import { ContactUs } from "./pages/contact/contact";
// import { About } from "./pages/about";
// import { Socialicons } from "./components/socialicons/socialicons";
// import { Portfolio } from "./pages/portfolio/portfolio";

export interface WithRouterProps {
  location: ReturnType<typeof useLocation>;
  params: Record<string, string>;
  navigate: ReturnType<typeof useNavigate>;
}

/** @deprecated Use `React Router hooks` instead */
export const withRouter = <Props extends WithRouterProps>(
  Component: React.ComponentType<Props>
) => {
  return (props: Omit<Props, keyof WithRouterProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    return (
      <Component
        {...(props as Props)}
        location={location}
        params={params}
        navigate={navigate}
      />
    );
  };
};


const AnimatedSwitch = withRouter(({ location }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.key}
      timeout={{
        enter: 0,
        exit: 0,
      }}
      classNames="page"
      unmountOnExit
    >
      <Routes location={location}>
        <Route path="/" element={<Suspense fallback={<></>}><Home/></Suspense>} />
        <Route path="/about" element={<Suspense fallback={<></>}><About/></Suspense>} />
        <Route path="/projects" element={<Suspense fallback={<></>}><Portfolio/></Suspense>} />
        <Route path="/projects/mobility" element={<Suspense fallback={<></>}><Mobility/></Suspense>} />
        <Route path="/projects/esplte" element={<Suspense fallback={<></>}><Esplte/></Suspense>} />
        <Route path="/contact" element={<Suspense fallback={<></>}><ContactUs/></Suspense>} />
        <Route path="/games" element={<Suspense fallback={<></>}><Games/></Suspense>} />
        <Route path="/games/spellingbee" element={<Suspense fallback={<></>}><Spellingbee/></Suspense>} />
        <Route path="/games/wordle" element={<Suspense fallback={<></>}><Wordle/></Suspense>} />
        <Route path="*" element={<Suspense fallback={<></>}><Home/></Suspense>} />
      </Routes>
    </CSSTransition>
  </TransitionGroup>
));

function AppRoutes() {
  return (
    <div className="s_c">
      <AnimatedSwitch />
      <Socialicons />
    </div>
  );
}

export default AppRoutes;
