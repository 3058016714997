// import React from 'react';
import React, { Suspense, lazy, useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import {
  BrowserRouter as Router,
  useLocation,
  // useNavigate,
  // useParams
} from "react-router-dom";
// import AppRoutes, {withRouter} from "./routes";
import {withRouter} from "./routes";
// import AppRoutes from "./routes";
// const AppRoutes = await import('./routes')
// const Styling = lazy(() => import('./styling'));
const AppRoutes = lazy(()=> import( "./routes"));
const Headermain = lazy(()=> import("./header"));
function _ScrollToTop(props:any) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);
if (process.env.NODE_ENV === "production"){
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}
export default function App() {
  // var userLang = navigator.language; 
  // alert ("The language is: " + userLang);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      {/* <div className="cursor__dot">
      </div> */}
      <ScrollToTop>
      <Suspense fallback={<></>} >
          {/* <Styling> */}
        <Headermain />
        <AppRoutes />
        {/* </Styling> */}
      </Suspense>
      </ScrollToTop>
    </Router>
  );
}




// function App() {
//   return (
//     <>
//   <Headermain />
//       {/* <RouterProvider router={router}>
//         <div>
//           <Link to="/">Home</Link> 
//           <Link to="/about">About</Link>
//           <Link to="/particles">Particles</Link>
//           <Link to="/map">Map</Link>
//         </div>
//         <hr />
//         <Outlet/>
//       </RouterProvider> */}
//     </>
//   )
// }

// function Index() {
//   return (
//     <div>
//       <h3>Welcome Home!</h3>
//     </div>
//   )
// }


const rootElement = document.getElementById('root')!
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(
    // <StrictMode>
      <App />
    // {/* </StrictMode>, */}
  )
}